<template>
  <div>
    <h1>Our vegan 🍪 policy</h1>
    <p>
      Legal: 
      <router-link to="privacypolicy">Privacy Policy</router-link>
      |
      <router-link to="cookiepolicy">Cookie Policy</router-link>
      |
      <router-link to="termsofservice">Terms of Service</router-link>
    </p>

    <div v-if="article" v-html="article.content" class="markdown mx-0 mt-4 dynamic-content"> </div>

    <v-btn class="primary" href="/contact">Contact Us</v-btn>

  </div>
</template>

<script>

import articleService from "@/services/articleService";

export default {
  name: "CookiePolicy",
  components: {
  },
  props: {
  },
  data: function() {
    return {
      article: null,
    };
  },
  created() {
  },
  async mounted() {
    this.article = await articleService.get('cookiepolicy');
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style scoped>
</style>